import { useQuery } from "@tanstack/react-query";
import axiosInstance from "./axiosInstance";



export const useFetchAllCountry = () => {
  return useQuery({
    queryKey: ["all_country"],
    queryFn: async () => {
      const response = await axiosInstance.get(`/countries/all-countries`);
      const data = response.data?.data || [];
      return data;
    },
    keepPreviousData: true, // Optional: Keeps old data while fetching new data
    staleTime: 5 * 60 * 1000, // Optional: Cache data for 5 minutes
  });
};
export const useFetchAllState = (country_id) => {
  return useQuery({
    queryKey: ["all_state", country_id],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/countries/all-states/${country_id}`
      );
      const data = response.data?.data || [];
      return data;
    },
    keepPreviousData: true, // Optional: Keeps old data while fetching new data
    staleTime: 5 * 60 * 1000, // Optional: Cache data for 5 minutes
    enabled: !!country_id, // Only run the query if country_id is provided
  });
};