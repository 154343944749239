import React from "react";
import PropTypes from "prop-types";

const AuthButton = ({
  type = "button",
  loading = false,
  disabled = false,
  children,
  className = "",
  ...rest
}) => {
  return (
    <button
      type={type}
      className={`bg-color1 rounded-lg h-14 w-full text-white mx-auto relative ${
        loading ? "opacity-50 pointer-events-none" : ""
      } ${className}`}
      disabled={loading || disabled}
      {...rest}
    >
      {loading && (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="loader"></div>
        </div>
      )}
      {loading ? "Loading..." : children}
    </button>
  );
};

AuthButton.propTypes = {
  type: PropTypes.string, // Button type (e.g., 'submit', 'button')
  loading: PropTypes.bool, // Whether the button is in a loading state
  disabled: PropTypes.bool, // Whether the button is disabled
  children: PropTypes.node.isRequired, // Button content (e.g., 'Next')
  className: PropTypes.string, // Additional custom classes
};

export default AuthButton;
