import React, {useEffect, useState} from "react";
import svg from "../../../../assets/images/Upload.svg";
import downloadsvg from "../../../../assets/images/download.svg";
import {useGlobalContext} from "../../../../custom-hooks/Context";
import axios from "../../../../utils/axiosInstance";
import {useCustomToast} from "../../../toast/useCustomToast";
import "./style.css";
import SuccessModal from "../../../layout/Modal/successModal";
import { useFetchAllCountry, useFetchAllState } from "../../../../utils/countryApis";
import { useForm } from "react-hook-form";
import AuthButton from "../../../common/AuthButton";

function Document({nextStep}) {
  const {
    setFirstname,
    setLastname,
    setAddress,
    firstname,
    lastname,
    address,
    lga,
    setLga,
    country,
    state,
    userId,
  } = useGlobalContext();
  const showToast = useCustomToast();

  const [loading, setLoading] = useState(false);
  const { successModalOpen, setSuccessModalOpen } = useGlobalContext();
  const { data: fetchCountry, isLoading: countryLoading } =
    useFetchAllCountry();
  const initialCountryId =
    fetchCountry?.find((c) => c.name === country)?.id || "";
  const [selectedCountryId, setSelectedCountryId] = useState(initialCountryId);
  const { data: fetchState, isLoading: statesLoading } =
    useFetchAllState(selectedCountryId);
    const [documentImage, setDocumentImage] = useState(null);
    const [utilityImage, setUtilityImage] = useState(null);
    const [cacImage, setCacImage] = useState(null);
    const [fileErrors, setFileErrors] = useState({
      documentImage: "",
      utilityImage: "",
      cacImage: "",
    });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: firstname || "",
      lastName: lastname || "",
      address: address || "",
      lga: lga || "",
      country: country || "",
      state: state || "",
      documentType: "",
    },
  });

 

  const validateFile = (file, fieldName) => {
    if (!file) return `${fieldName} is required`;
    const fileType = file.type;
    const validTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validTypes.includes(fileType)) {
      return "Please upload a valid image file (PNG, JPG, JPEG)";
    }
    return "";
  };


  console.log(documentImage, "documentImage");
  // Debug file inputs
  useEffect(() => {
    console.log("documentImage:", documentImage);
    console.log("utilityImage:", utilityImage);
    console.log("cacImage:", cacImage);
  }, [documentImage, utilityImage, cacImage]);


  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    const error = validateFile(file, fieldName);
console.log(file, "file file file")

    switch (fieldName) {
      case "Document image":
        setDocumentImage(file || null);
        setFileErrors((prev) => ({ ...prev, documentImage: error }));
        break;
      case "Utility bill":
        setUtilityImage(file || null);
        setFileErrors((prev) => ({ ...prev, utilityImage: error }));
        break;
      case "CAC certificate":
        setCacImage(file || null);
        setFileErrors((prev) => ({ ...prev, cacImage: error }));
        break;
      default:
        break;
    }
  };


  const onSubmit = async (data) => {

    const documentError = validateFile(documentImage, "Document image");
    const utilityError = validateFile(utilityImage, "Utility bill");
    const cacError = validateFile(cacImage, "CAC certificate");

    setFileErrors({
      documentImage: documentError,
      utilityImage: utilityError,
      cacImage: cacError,
    });

    if (documentError || utilityError || cacError) {
      return; 
    }
    try {
      setLoading(true);

      const postData = new FormData();
      postData.append("first_name", data.firstName);
      postData.append("last_name", data.lastName);
      postData.append("user_id", userId);
      postData.append("business_address", data.address);
      postData.append("lga", data.lga);
      postData.append("country", data.country);
      postData.append("state", data.state);
      postData.append("document_type", data.documentType);
      postData.append("document_image", documentImage);
      postData.append("utility_image", utilityImage);
      postData.append("cac", cacImage);

    
      const response = await axios.post("/onboarding/bioData", postData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status) {
        // Update global context with form data

        showToast("BioData submitted successfully", "success");
        setFirstname(data.firstName);
        setLastname(data.lastName);
        setAddress(data.address);
        setLga(data.lga);

        setSuccessModalOpen(true);
        // nextStep(); 
      }
    } catch (error) {
      if (error.response) {
        const { status, message, errors } = error.response.data;
        if (status === "Error") {
          if (message && errors.length === 0) {
            showToast(message, "error");
          } else if (message && errors.length > 0) {
            showToast(errors[0], "error");
          }
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    const selectedCountry = fetchCountry?.find((c) => c.name === countryName);
    setSelectedCountryId(selectedCountry?.id || "");
    setValue("country", countryName); // Update form value
    setValue("state", ""); 
  };



  const downloadForm = () => {
    const fileUrl = process.env.PUBLIC_URL + "/GUARANTOR form.pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "GUARANTOR form.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
      <div className="md:m-8 my-8 overflow-hidden">
        <div className="md:p-16 py-16 px-8  md:bg-bg-green md:border-border-primary bg-white border-white rounded-3xl border">
          <div className="text-deep-green font-bold text-center">
            <p className="text-2xl">Biodata</p>
            <p className="text-gray-700 text-xl font-thin w-[360px]">
              Be sure to enter your legal name as it appears on your
              government-issued ID
            </p>
          </div>
          <div className="w-[350px] mt-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex gap-5">
                <div className=" w-full">
                  <p className="text-gray-700 text-lg mb-2">Legal First Name</p>
                  <input
                    type="text"
                    {...register(
                      "firstName",
                         {
                        required: "First name is required",
                      }
                    )}
                    readOnly
                    placeholder="Type First Name"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full mb-6 p-4"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.firstName.message}
                    </p>
                  )}
                </div>
                <div className=" w-full">
                  <p className="text-gray-700 text-lg mb-2">Legal Last Name</p>
                  <input
                    type="text"
                    {...register(
                      "lastName",
                         {
                        required: "Last name is required",
                      }
                    )}
                    readOnly
                    placeholder="Type Last Name"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14  w-full mb-6 p-4"
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lastName.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-5">
                <div className=" w-full">
                  <p className="text-gray-700 text-lg mb-2">Address</p>
                  <input
                    type="text"
                    {...register(
                      "address",
                        {
                        required: "Address is required",
                      }
                    )}
                    placeholder="Type Address"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14  w-full mb-6 p-4"
                  />
                  {errors.address && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.address.message}
                    </p>
                  )}
                </div>
                <div className=" w-full">
                  <p className="text-gray-700 text-lg mb-2">Local Govt</p>
                  <input
                    type="text"
                    {...register(
                      "lga",
                         {
                        required: "Local government area is required",
                      }
                    )}
                    placeholder="Type Local Govt Area"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14  w-full mb-6 p-4"
                  />
                  {errors.lga && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lga.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex gap-5">
                <div className=" w-full">
                  <p className="text-gray-700 text-lg mb-2">Country</p>
                  <select
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full mb-6 p-4"
                    {...register("country", {
                      required: "Country is required",
                    })}
                    onChange={handleCountryChange}
                    disabled={countryLoading}
                  >
                    <option value="" disabled hidden>
                      {country || "Select Country"}
                    </option>
                    {fetchCountry?.map((country) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.country.message}
                    </p>
                  )}
                </div>
                <div className=" w-full">
                  <p className="text-gray-700 text-lg mb-2">State</p>
                  <select
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full mb-6 p-4"
                    {...register("state", { required: "State is required" })}
                    disabled={statesLoading || !selectedCountryId}
                  >
                    <option value="" disabled>
                      {statesLoading ? "Loading..." : state || "Select State"}
                    </option>
                    {fetchState?.map((state) => (
                      <option key={state.id} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.state.message}
                    </p>
                  )}
                </div>
              </div>

              <div className="text-deep-green font-bold text-left gap-2 mb-2 flex flex-col">
                <p className="text-lg">Guarantor Form</p>
                <p className="text-gray-700 text-sm font-thin w-[360px]">
                  Download and Upload a signed copy of this form in your profile
                </p>
              </div>
              <div className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full mb-6 p-6 flex items-center justify-between">
                <div className="flex gap-2">
                  <img
                    src={downloadsvg} // Provide the actual path to your SVG upload icon
                    alt="Upload Icon"
                    className="h-10 w-10"
                  />
                  <div className="flex flex-col">
                    <p className="text-sm text-gray-900">
                      Download Guarantor Form
                    </p>
                    <p className="block text-gray-400 text-xs">
                      Guarantor form | 10MB max.
                    </p>
                  </div>
                </div>
                <div className="mb-2">
                  <button
                    type="button"
                    className="bg-[#ECE9FC] py-2 px-4 mt-2 rounded-md text-deep-green"
                    onClick={downloadForm}
                  >
                    Download
                  </button>
                </div>
              </div>

              <div className="text-deep-green font-bold text-left gap-2 mb-2">
                <p className="text-lg">Means of ID</p>
                <p className="text-gray-700 text-sm font-thin w[360px]">
                  Select and upload a valid identification document
                </p>
              </div>
              <select
                className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full mb-6 p-4"
                {...register("documentType", {
                  required: "Document type is required",
                })}
              >
                <option value="">Choose Document Type</option>
                <option value="drivers-license">Driver's License</option>
                <option value="nin-id">NIN ID</option>
                <option value="int-passport">Int Passport</option>
              </select>
              {errors.documentType && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.documentType.message}
                </p>
              )}

              <div className="relative">
                <div className="border border-gray-300 border-dotted p-2 rounded-md h-16 w-full ">
                  <div className=" flex gap-5 items-center justify-between">
                    <div className="flex gap-2">
                      <img
                        src={svg} // Provide the actual path to your SVG upload icon
                        alt="Upload Icon"
                        className="h-10 w-10"
                      />
                      <div className="flex flex-col">
                        <p className="text-sm text-gray-900">Tap to Upload</p>
                        <p className="block text-gray-400 text-xs">
                          PNG, JPG | 10MB max
                        </p>
                      </div>
                    </div>
                    <div>
                      
                      <input
                        type="file"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={(e) => handleFileChange(e, "Document image")}
                      />
                    </div>
                  </div>
                  {fileErrors.documentImage && (
                    <p className="text-red-500 text-sm mt-1">
                      {fileErrors.documentImage}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-6">
                <p className="text-gray-700 text-lg mb-2">Utilities Bill</p>
                <div className="relative">
                  <div className="border border-gray-300 border-dotted p-2 rounded-md h-16 w-full ">
                    <div className=" flex gap-5 items-center justify-between">
                      <div className="flex gap-2">
                        <img
                          src={svg} // Provide the actual path to your SVG upload icon
                          alt="Upload Icon"
                          className="h-10 w-10"
                        />
                        <div className="flex flex-col">
                          <p className="text-sm text-gray-900">Tap to Upload</p>
                          <p className="block text-gray-400 text-xs">
                            PNG, JPG | 3MB max
                          </p>
                        </div>
                      </div>
                      <div>
                       

                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => handleFileChange(e, "Utility bill")}
                        />
                      </div>
                    </div>
                    {fileErrors.utilityImage && (
                      <p className="text-red-500 text-sm mt-1">
                        {fileErrors.utilityImage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-6">
                <p className="text-gray-700 text-lg mb-2">CAC certificate</p>
                <div className="relative">
                  <div className="border border-gray-300 border-dotted p-2 rounded-md h-16 w-full ">
                    <div className=" flex gap-5 items-center justify-between">
                      <div className="flex gap-2">
                        <img
                          src={svg} // Provide the actual path to your SVG upload icon
                          alt="Upload Icon"
                          className="h-10 w-10"
                        />
                        <div className="flex flex-col">
                          <p className="text-sm text-gray-900">Tap to Upload</p>
                          <p className="block text-gray-400 text-xs">
                            PNG, JPG | 3MB max
                          </p>
                        </div>
                      </div>
                      <div>
                       
                        <input
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) =>
                            handleFileChange(e, "CAC certificate")
                          }
                        />
                      </div>
                    </div>
                    {fileErrors.cacImage && (
                      <p className="text-red-500 text-sm mt-1">
                        {fileErrors.cacImage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex p-2 mt-4">
                <AuthButton
                  type="submit"
                  loading={loading}
                  disabled={loading}
                  className="md:w-[60%] w-[30%]"
                >
                  Submit
                </AuthButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      {successModalOpen && <SuccessModal />}
    </>
  );
}

export default Document;
