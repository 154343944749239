import React, {useState} from 'react';
import axios from '../../../../utils/axiosInstance'; // Import Axios
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {useCustomToast} from '../../../toast/useCustomToast';
import {useGlobalContext} from '../../../../custom-hooks/Context';
import {POST_ONBOARDING_CONTACTINFO} from '../../../../utils/constants';
import { useForm } from 'react-hook-form';
import AuthButton from '../../../common/AuthButton';

const Contact = ({nextStep}) => {
    const showToast = useCustomToast();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { setPhoneNum, setPass, email, setEmail } = useGlobalContext();
     const {
       register,
       handleSubmit,
       watch,
       formState: { errors },
     } = useForm({
       defaultValues: {
         phoneNumber: "",
         businessName: "",
         businessType: "Agent",
         gender: "Male",
         email: email || "",
         password: "",
         confirmPassword: "",
       },
     });

     const password = watch("password");

     const togglePasswordVisibility = () => {
       setShowPassword((prev) => !prev);
     };

     const toggleConfirmPasswordVisibility = () => {
       setShowConfirmPassword((prev) => !prev);
     };

   
   
    const onSubmit = async (data) => {
      // Check if passwords match (additional safety net, though validated by react-hook-form)
      if (data.password !== data.confirmPassword) {
        showToast("Passwords do not match.", "error");
        return;
      }

      try {
        setLoading(true);
        const payload = {
          phone: data.phoneNumber,
          password: data.password,
          password_confirmation: data.confirmPassword,
          email: data.email,
          business_name: data.businessName,
          gender: data.gender,
          business_type: data.businessType,
        };

        const response = await axios.post(POST_ONBOARDING_CONTACTINFO, payload);
        
        if (response?.status){
            const responseData = response.data;
            console.log(responseData)
            
            showToast(
              "A code has been sent to your registered phone and email.",
              "success"
            );
            setPhoneNum(data.phoneNumber);
            setPass(data.password);
            setEmail(data.email); // Update global email state
            nextStep();
        }
      } catch (error) {
        if (error.response) {
          const { status, message, errors } = error.response.data;
          if (status === "Error") {
            if (message && errors.length === 0) {
              showToast(message, "error");
            } else if (message && errors.length > 0) {
              showToast(errors[0], "error");
            }
          }
        }
      } finally {
        setLoading(false);
      }
    };

  

    return (
      <>
        <div className="md:m-8 my-8 overflow-hidden">
          <div className="md:p-16 py-16 px-8 md:bg-bg-green md:border-border-primary bg-white border-white rounded-3xl border">
            <div className="text-color1 font-bold text-center">
              <p className="text-2xl font-bold">Contact Information</p>
              <p className="text-gray-500 text-xl font-thin w-[360px]">
                Enter your contact details to get started
              </p>
            </div>
            <div className="w-[350px] mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="text-lg">
                <div className="mb-6">
                  <label htmlFor="phoneNumber" className="text-gray-700 mb-2">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    {...register("phoneNumber", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^\d{10,}$/,
                        message: "Phone number must be at least 10 digits",
                      },
                    })}
                    placeholder="Enter your phone number"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                  />
                  {errors.phoneNumber && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.phoneNumber.message}
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label htmlFor="businessName" className="text-gray-700 mb-2">
                    Business Name
                  </label>
                  <input
                    type="text"
                    id="businessName"
                    {...register("businessName", {
                      required: "Business name is required",
                    })}
                    placeholder="Enter your Business Name"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                  />
                  {errors.businessName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.businessName.message}
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label htmlFor="businessType" className="text-gray-700 mb-2">
                    Select Business Type:
                  </label>
                  <select
                    id="businessType"
                    className="bg-bg-green border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                    {...register("businessType", {
                      required: "Business type is required",
                    })}
                  >
                    <option value="agent">Individual/Agent</option>
                    <option value="merchant" disabled>
                      Merchant
                    </option>
                  </select>
                  {errors.businessType && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.businessType.message}
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label htmlFor="gender" className="text-gray-700 mb-2">
                    Gender:
                  </label>
                  <select
                    id="gender"
                    className="bg-bg-green border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                    {...register("gender", {
                      required: "Gender is required",
                    })}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                  {errors.gender && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.gender.message}
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label htmlFor="email" className="text-gray-700 mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                    placeholder="Enter your email"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="relative mb-6">
                  <label htmlFor="password" className="text-gray-700 mb-2">
                    Password
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters",
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%.?&])[A-Za-z\d@$!%?&#]+$/,
                        message:
                          "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character (@$#!%.?&)",
                      },
                    })}
                    placeholder="**********"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                  {errors.password && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="relative mb-6">
                  <label
                    htmlFor="confirmPassword"
                    className="text-gray-700 mb-2"
                  >
                    Confirm Password
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    {...register("confirmPassword", {
                      required: "Confirm password is required",
                      validate: (value) =>
                        value === password || "Passwords do not match",
                    })}
                    placeholder="**********"
                    className="md:bg-bg-green bg-white border-[#D0D5DD] border rounded-lg h-14 w-full p-4"
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEye : faEyeSlash}
                    className="absolute top-1/2 right-3 transform -translate-y-1/2 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
                <AuthButton type="submit" loading={loading}>
                  Next
                </AuthButton>
                
              </form>
            </div>
          </div>
        </div>
      </>
    );
};

export default Contact;
